'use strict';

import Flux from '../../../flux/Flux';

function MobileRoutes(routesConfig, language) {
	this.routesConfig = routesConfig;
	this.language     = language;
}

MobileRoutes.prototype.STARTPAGE                           = 'Startpage';
MobileRoutes.prototype.HOMEPAGE                            = 'NewHomePage';
MobileRoutes.prototype.DAILYBONUSPAGE                      = 'DailyBonus';
MobileRoutes.prototype.VXMOBILE                            = 'VXMobile';
MobileRoutes.prototype.ACTOR_LANDINGPAGE_LOCALIZED         = 'ActorLandingPageLocalized';
MobileRoutes.prototype.ACTOR_LANDINGPAGE_FSK12             = 'ActorLandingPageFsk12Localized';
MobileRoutes.prototype.ADWORDS_LANDING_PAGE                = 'AdWordsLandingPage';
MobileRoutes.prototype.CAMS_SLUG                           = 'CamsSlug';
MobileRoutes.prototype.CAMS                                = 'Cams';
MobileRoutes.prototype.ACTOR_SEARCH_NEW_GIRLS              = 'ActorSearchNewGirls';
MobileRoutes.prototype.CAM_SHOWS                           = 'CamShows';
MobileRoutes.prototype.ACTOR_PROFILE_OVERVIEW              = 'Actor/ProfileOverview';
MobileRoutes.prototype.ACTOR_PROFILE_VIDEOS                = 'Actor/ProfileVideos';
MobileRoutes.prototype.ACTOR_PROFILE_VIDEOALBUM_DETAIL     = 'Actor/ProfileVideoAlbumDetail';
MobileRoutes.prototype.ACTOR_PROFILE_ABOUT                 = 'Actor/ProfileAbout';
MobileRoutes.prototype.ACTOR_PROFILE_LIVE_SHOW             = 'Actor/ProfileLiveShow';
MobileRoutes.prototype.ACTOR_PROFILE_TOP_CAMS              = 'Actor/ProfileTopCams';
MobileRoutes.prototype.ACTOR_PROFILE_VIDEOS_TAG_GROUP      = 'Actor/ProfileVideos/TagGroup';
MobileRoutes.prototype.ACTOR_PROFILE_VIDEOS_CLASSIC        = 'Actor/ProfileVideos/Classic';
MobileRoutes.prototype.MYVISITX                            = 'MyVisitX';
MobileRoutes.prototype.MYVISITX_FAVORITE_ACTORS            = 'MyVisitXFavoriteActors';
MobileRoutes.prototype.MYVISITX_MY_VIDEOCHATS              = 'MyVisitXMyVideoChats';
MobileRoutes.prototype.MYVISITX_GUEST_PROFILE              = 'MyVisitXGuestProfile';
MobileRoutes.prototype.MYVISITX_GUEST_PROFILE_DATA         = 'MyVisitXGuestProfileData';
MobileRoutes.prototype.MYVISITX_GUEST_PROFILE_PREFS        = 'MyVisitXGuestProfilePrefs';
MobileRoutes.prototype.MYVISITX_PHOTOS_GIFT                = 'MyVisitXPhotosGift';
MobileRoutes.prototype.MYVISITX_VIDEOS_GIFT                = 'MyVisitXVideosGift';
MobileRoutes.prototype.MYVISITX_PHOTOS_PRIVATE_COLLECTION  = 'MyVisitXPhotosPrivateCollection';
MobileRoutes.prototype.MYVISITX_VIDEOS_PRIVATE_COLLECTION  = 'MyVisitXVideosPrivateCollection';
MobileRoutes.prototype.MYVISITX_VIDEOS_LIMITED             = 'MyVisitXVideosLimited';
MobileRoutes.prototype.MYVISITX_PRIVATE_COLLECTION         = 'MyVisitXPrivateCollection';
MobileRoutes.prototype.BOUGHT_CONTENT_OVERVIEW             = 'BoughtContentOverview';
MobileRoutes.prototype.MYVISITX_BOUGHT_PHOTOS              = 'MyVisitXBoughtPhotos';
MobileRoutes.prototype.MYVISITX_BOUGHT_VIDEOS              = 'MyVisitXBoughtVideos';
MobileRoutes.prototype.MYVISITX_PINBOARD_PHOTOS            = 'MyVisitXPhotosPinBoard';
MobileRoutes.prototype.MYVISITX_PINBOARD_PHOTOS_ALBUM      = 'MyVisitXPhotosPinBoardAlbum';
MobileRoutes.prototype.MYVISITX_PINBOARD_VIDEOS            = 'MyVisitXVideosPinBoard';
MobileRoutes.prototype.MYVISITX_BENEFITS                   = 'MyVisitXBenefits';
MobileRoutes.prototype.MYVISITX_SPINWHEEL                  = 'MyVisitXSpinwheel';
MobileRoutes.prototype.MYVISITX_MEDIATHEK                  = 'MyVisitXMediathek';
MobileRoutes.prototype.MYVISITX_MEDIATHEK_VIDEOS           = 'MyVisitXMediathekVideos';
MobileRoutes.prototype.MYVISITX_MEDIATHEK_VIDEOS_FEED      = 'MyVisitXMediathekVideosFeed';
MobileRoutes.prototype.MYVISITX_MEDIATHEK_VIDEOS_MESSENGER = 'MyVisitXMediathekVideosMessenger';
MobileRoutes.prototype.MYVISITX_MEDIATHEK_VIDEOS_PINNED    = 'MyVisitXMediathekVideosPinned';
MobileRoutes.prototype.MYVISITX_MEDIATHEK_PHOTOS           = 'MyVisitXMediathekPhotos';
MobileRoutes.prototype.MYVISITX_MEDIATHEK_PHOTOS_FEED      = 'MyVisitXMediathekPhotosFeed';
MobileRoutes.prototype.MYVISITX_MEDIATHEK_PHOTOS_MESSENGER = 'MyVisitXMediathekPhotosMessenger';
MobileRoutes.prototype.MYVISITX_MEDIATHEK_PHOTOS_PINNED    = 'MyVisitXMediathekPhotosPinned';
MobileRoutes.prototype.MYVISITX_MEDIATHEK_AUDIO            = 'MyVisitXMediathekAudio';
MobileRoutes.prototype.CATEGORIES_OVERVIEW                 = 'CategoriesOverview';
MobileRoutes.prototype.CATEGORIES_TOP_CAMS                 = 'CategoriesTopCams';
MobileRoutes.prototype.CATEGORIES_TOP_CAMS_BEST_CHATS      = 'CategoriesTopCamsBestChats';
MobileRoutes.prototype.CATEGORIES_TOP_CAMS_HD_CAMS         = 'CategoriesTopCamsHDCams';
MobileRoutes.prototype.CATEGORIES_TOP_CAMS_TOP_FAVORITES   = 'CategoriesTopCamsTopFavorites';
MobileRoutes.prototype.COMPLIANCE_NOTICE_2257              = 'ComplianceNotice2257';
MobileRoutes.prototype.DATA_PROTECTION                     = 'DataProtection';
MobileRoutes.prototype.IMPRINT                             = 'Imprint';
MobileRoutes.prototype.TERMS_OF_SERVICE                    = 'TermsOfService';
MobileRoutes.prototype.MESSENGER                           = 'Messenger';
MobileRoutes.prototype.SEARCH_RESULTS                      = 'SearchResults';
MobileRoutes.prototype.SEARCH_RESULTS_ACTORS               = 'SearchResultsActors';
MobileRoutes.prototype.SEARCH_RESULTS_VIDEOS               = 'SearchResultsVideos';
MobileRoutes.prototype.SEARCH_RESULTS_PHOTOS               = 'SearchResultsPhotos';
MobileRoutes.prototype.SEARCH_RESULTS_MEDIATHEK            = 'SearchResultsMediathek';
MobileRoutes.prototype.SEARCH_RESULTS_MAGAZINE             = 'SearchResultsMagazine';
MobileRoutes.prototype.LOGIN                               = 'Login';
MobileRoutes.prototype.NEWSLETTER_UNSUBSCRIBE              = 'NewsletterUnsubscribe';
MobileRoutes.prototype.RESTRICTED                          = 'Restricted';
MobileRoutes.prototype.BLOG_ARTICLE                        = 'Blog/Article';
MobileRoutes.prototype.MAGAZINE                            = 'Magazine';
MobileRoutes.prototype.MAGAZINE_ARCHIVE                    = 'MagazineArchive';
MobileRoutes.prototype.MAGAZINE_MAGAZINES_OVERVIEW         = 'MagazineMagazines';
MobileRoutes.prototype.MAGAZINE_MAGAZINES_DETAILS          = 'MagazineMagazinesDetails';
MobileRoutes.prototype.TV                                  = 'TV';
MobileRoutes.prototype.TV_LIVESTREAM                       = 'TV/Livestream';
MobileRoutes.prototype.TV_PREMIUM                          = 'TV/Premium';
MobileRoutes.prototype.TV_PREMIUM_LIVESTREAM               = 'TV/Premium/Livestream';
MobileRoutes.prototype.TV_PREMIUM_PRICETABLE               = 'TV/Premium/Pricetable';
MobileRoutes.prototype.TV_PROGRAM                          = 'TV/Program';
MobileRoutes.prototype.TV_MEDIA                            = 'TV/Media';
MobileRoutes.prototype.TV_MEDIA_SERIES                     = 'TV/Media/Series';
MobileRoutes.prototype.TV_MEDIA_SERIES_OVERVIEW            = 'TV/Media/SeriesOverview';
MobileRoutes.prototype.TV_MEDIA_VIDEOS_OVERVIEW            = 'TV/Media/VideosOverview';
MobileRoutes.prototype.TV_SERIES_EPISODE                   = 'TV/SeriesEpisode';
MobileRoutes.prototype.TV_GIRLS                            = 'TV/Girls';
MobileRoutes.prototype.TV_LATE_NIGHT                       = 'TV/LateNight';
MobileRoutes.prototype.CATEGORIES_CAMS_NEXT_DOOR           = 'CategoriesCamsNextDoor';
MobileRoutes.prototype.SPECIALS_EVENTS                     = 'Specials/Events';
MobileRoutes.prototype.SPECIAL_GOLDRUSH                    = 'Special/Goldrush';
MobileRoutes.prototype.SPECIAL_ADVENT_CALENDAR             = 'Special/AdventCalendar';
MobileRoutes.prototype.SPECIAL_TWENTY_FIVE_YEARS           = 'Special/TwentyFiveYears';
MobileRoutes.prototype.SPECIAL_CUM_ON                      = 'Special/CumOn';
MobileRoutes.prototype.SPECIAL_VX_CALENDAR                 = 'Special/VXCalendar';
MobileRoutes.prototype.SPECIAL_SLOT_MACHINE                = 'Special/SlotMachine';
MobileRoutes.prototype.SPECIAL_SLOT_MACHINE_ARCHIVE        = 'Special/SlotMachineArchive';
MobileRoutes.prototype.SPECIAL_KICK_IT_2021                = 'Special/KickIt/2024';
MobileRoutes.prototype.SPECIAL_LEXY360                     = 'Special/Lexy360';
MobileRoutes.prototype.SPECIAL_WIESN_SHOOTING              = 'Special/WiesnShooting';
MobileRoutes.prototype.SPECIAL_WET_AND_WILD                = 'Special/WetAndWild';
MobileRoutes.prototype.SPECIAL_WIESN_BUDE                  = 'Special/WiesnBude';
MobileRoutes.prototype.SPECIAL_VR_PORN                     = 'Special/VRPorn';
MobileRoutes.prototype.SPECIAL_EASTER                      = 'Special/Easter';
MobileRoutes.prototype.CONTESTS_OVERVIEW                   = 'Contests';
MobileRoutes.prototype.CONTESTS_DETAILS                    = 'ContestsDetails';
MobileRoutes.prototype.CONTESTS_DETAILS_TOP10              = 'ContestsDetailsTop10';
MobileRoutes.prototype.CONTESTS_DETAILS_VOTED              = 'ContestsDetailsVoted';
MobileRoutes.prototype.CONTESTS_DETAILS_NOT_VOTED          = 'ContestsDetailsNotVoted';
MobileRoutes.prototype.CONTESTS_DETAILS_ACTOR              = 'ContestsDetailsActor';
MobileRoutes.prototype.VIDEOS_INDEX                        = 'Videos/Index';
MobileRoutes.prototype.VIDEOS_ALL_CATEGORY                 = 'VideosAll';
MobileRoutes.prototype.VIDEOS_NEW_CATEGORY                 = 'VideosNew';
MobileRoutes.prototype.VIDEOS_TRENDING_CATEGORY            = 'VideosTrending';
MobileRoutes.prototype.VIDEOS_FREE_CLIPS_CATEGORY          = 'VideosFreeClips';
MobileRoutes.prototype.VIDEOS_TV_SERIES_CATEGORY           = 'VideosTvSeries';
MobileRoutes.prototype.VIDEOS_ON_SALE_CATEGORY             = 'VideosOnSale';
MobileRoutes.prototype.VIDEOS_STARS                        = 'VideosStars';
MobileRoutes.prototype.FREE_CHAT                           = 'ActorSearchFreeChat';
MobileRoutes.prototype.VIP_CLUB                            = 'VIPClub';
MobileRoutes.prototype.VIP30                               = 'videos/vip-videos';
MobileRoutes.prototype.CLASHOFCAMS_EVENT                   = 'ClashOfCamsEvent';
MobileRoutes.prototype.FEED                                = 'Feed';
MobileRoutes.prototype.VXSELECT                            = 'VXSelect';
MobileRoutes.prototype.VX_20_JAHRE                         = 'Vx20Jahre';
MobileRoutes.prototype.NOT_FOUND                           = 'NotFound';
MobileRoutes.prototype.PLAYGROUND                          = 'Playground';
MobileRoutes.prototype.USER_ACTIVITIES                     = 'UserActivities';
MobileRoutes.prototype.USER_ACTIVITIES_CHALLENGE           = 'UserActivitiesChallenge';
MobileRoutes.prototype.USER_ACTIVITIES_HIGH_ON_ROSE        = 'UserActivitiesHighOnRose';

// special unknown routeName for conversion API - this route name has no corresponding route!
MobileRoutes.prototype.UNKNOWN = 'UNKNOWN';

MobileRoutes.prototype.getPathByName = function(routeName, baseRoute = "", useAsterix = false) {
    if (Object.hasOwn(this.routesConfig, routeName)) {
        return (baseRoute ? this.routesConfig[routeName].replace(baseRoute, "/") : this.routesConfig[routeName]) + (useAsterix ? "*" : "");
    }
	return  '/das/ist/keine/route';
};

MobileRoutes.prototype.getLink = function(routeName, params) {
	let link = this.routesConfig[routeName];
	if (routeName === this.VXMOBILE && this.language === Flux.Constants.Languages.DE) {
		link = '/';
	}

	if (!params) {
		params = {};
	}

	params[':language'] = this.language;

	if (!link) {
		/* eslint-disable */
		console.error('No link found for route:' + routeName);
		/* eslint-enable */
		return '';
	}

	return link.replace(/(:[A-Za-z0-9]+)\/?/g, function($1, $2) {
		return params[$2] + '/';
	}).toLowerCase();
};

export default new MobileRoutes(window.VXConfig.routes, window.VXConfig.language);
